import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../infrastructure/store";
import requestAnalysisApi from "./api/requestAnalysis.api";

export interface SelectedPatient {
  id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
}

interface RequestAnalysisState {
  selectedPatient?: SelectedPatient;
  selectedAnalyses: string[];
}

const initialState: RequestAnalysisState = {
  selectedPatient: undefined,
  selectedAnalyses: [],
};

export const requestAnalysisSlice = createSlice({
  name: "requestAnalysis",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      requestAnalysisApi.endpoints.requestAnalyses.matchFulfilled,
      (state) => {
        state.selectedAnalyses = initialState.selectedAnalyses;
        state.selectedPatient = initialState.selectedPatient;
      },
    );
  },
  reducers: {
    patientSelected: (state, action: PayloadAction<SelectedPatient>) => {
      state.selectedPatient = action.payload;
    },
    patientUnselected: (state) => {
      state.selectedPatient = undefined;
    },
    analysisSelected: (state, action: PayloadAction<string>) => {
      const filteredList = state.selectedAnalyses.filter(
        (id) => id !== action.payload,
      );

      if (filteredList.length !== state.selectedAnalyses.length) {
        state.selectedAnalyses = filteredList;
      } else {
        state.selectedAnalyses.push(action.payload);
      }
    },
  },
});

export const { patientSelected, patientUnselected, analysisSelected } =
  requestAnalysisSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectRequestAnalysisSelectedPatient = (state: RootState) =>
  state.requestAnalysis.selectedPatient;

export const selectedAnalysis = (state: RootState) =>
  state.requestAnalysis.selectedAnalyses;

export default requestAnalysisSlice;
