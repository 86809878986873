import { initializeApp } from "firebase/app";

import Constants from "expo-constants";

// Optionally import the services that you want to use
// import {...} from "firebase/auth";
// import {...} from "firebase/database";
// import {...} from "firebase/firestore";
// import {...} from "firebase/functions";
// import {...} from "firebase/storage";

// Initialize Firebase
const firebaseConfig = {
  apiKey: Constants.expoConfig?.extra?.fireBaseApiKey as string,
  authDomain: "admin.medilab.com.sv",
  databaseURL: "https://medilab-fe057-default-rtdb.firebaseio.com",
  projectId: "medilab-fe057",
  storageBucket: "medilab-fe057.appspot.com",
  messagingSenderId: "679960860099",
  appId: "1:679960860099:web:c122a3af4191615b739976",
  measurementId: "G-C8MLL62YC7",
};

export const firebaseApp = initializeApp(firebaseConfig);
// For more information on how to access Firebase in your project,
// see the Firebase documentation: https://firebase.google.com/docs/web/setup#access-firebase
