import * as Yup from "yup";

interface FormikErrors {
  [key: string]: string;
}

export function convertYupToFormikErrors(
  validationError: Yup.ValidationError,
): FormikErrors {
  const formikErrors: FormikErrors = {};

  if (!validationError.inner && validationError.path) {
    formikErrors[validationError.path] = validationError.message;
    return formikErrors;
  }

  validationError.inner.forEach((error) => {
    if (error.path) {
      formikErrors[error.path] = error.message;
    }
  });

  return formikErrors;
}
