// import { auth } from "firebaseui";
import { firebaseApp } from "../../../firebaseConfig";
import {
  getAuth,
  // initializeAuth,
  // browserLocalPersistence,
  // connectAuthEmulator,
} from "firebase/auth";
// import * as firebaseui from 'firebaseui'

export const appAuth = getAuth(firebaseApp);
appAuth.useDeviceLanguage();

// connectAuthEmulator(appAuth, "http://127.0.0.1:9099");

// export const authUI = new firebaseui.auth.AuthUI(appAuth)

// initializeAuth(firebaseApp,
//     {
//      persistence: browserLocalPersistence
//    }
//  )
