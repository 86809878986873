import React, { useState, createContext, useEffect } from "react";

import { loginRequest, logoutRequest } from "./authentication.service";
import {
  User,
  UserCredential,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import { appAuth } from "./config";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../storage/config";

type Role = "admin" | "doctor" | null;

interface AuthenticationContextProps {
  user: User | null;
  role: Role;
  isLoading: boolean;
  error: Error | null;
  isAuthenticated: boolean;
  onLogin: (email: string, password: string) => void;
  onRegister: (
    email: string,
    password: string,
    repeatedPassword: string,
  ) => void;
  onLogout: () => void;
}

export const AuthenticationContext = createContext<AuthenticationContextProps>({
  user: null,
  role: null,
  isLoading: false,
  error: null,
  isAuthenticated: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onLogin: function (email: string, password: string): void {
    throw new Error("Function not implemented.");
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onRegister: function (
    email: string,
    // password: string,
    // repeatedPassword: string,
  ): void {
    throw new Error("Function not implemented. " + email);
  },
  onLogout: function (): void {
    throw new Error("Function not implemented.");
  },
});

interface AuthenticationContextProviderProps {
  children: JSX.Element;
}

export const AuthenticationContextProvider = ({
  children,
}: AuthenticationContextProviderProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const [role, setRole] = useState<Role>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(appAuth, (user) => {
      if (!user) {
        // No user is signed in, set user to null
        setUser(null);
        setIsLoading(false);

        return;
      }

      const storeUserAndRole = async () => {
        // User is signed in, set the user object
        setUser(user);

        const userDoc = await getDoc(doc(db, "users", user.uid));
        const role = userDoc.get("role") as Role;
        setRole(role);

        setIsLoading(false);
      };

      void storeUserAndRole();
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const onLogin = (email: string, password: string) => {
    setIsLoading(true);
    loginRequest(email, password)
      .then(async (u: UserCredential) => {
        setUser(u.user);

        const userDoc = await getDoc(doc(db, "users", user!.uid));
        const role = userDoc.get("role") as Role;
        setRole(role);

        setIsLoading(false);
      })
      .catch((e: Error) => {
        setIsLoading(false);
        setError(e);
      });
  };

  const onRegister = (
    email: string,
    password: string,
    repeatedPassword: string,
  ) => {
    setIsLoading(true);
    if (password !== repeatedPassword) {
      setError(new Error("Error: Passwords do not match"));
      return;
    }
    createUserWithEmailAndPassword(appAuth, email, password)
      .then((u) => {
        setUser(u.user);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        setError(e.toString());
      });
  };

  const onLogout = () => {
    void logoutRequest().then(() => {
      setUser(null);
      setRole(null);
      setError(null);
    });
  };

  return (
    <AuthenticationContext.Provider
      value={{
        user,
        role,
        isLoading,
        error,
        onLogin,
        onRegister,
        onLogout,
        isAuthenticated: !!user,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
