import React, { useCallback, useMemo } from "react";
import { Snackbar } from "react-native-paper";
import { clearToast } from "../app.slice";
import { useAppSelector, useAppDispatch } from "../hooks";

export const AppToast = function AppToast() {
  const toastNotificationMessage = useAppSelector(
    (state) => state.app.toastNotificationMessage,
  );
  const dispatcher = useAppDispatch();
  const dismissToast = useCallback(
    () => dispatcher(clearToast()),
    [dispatcher, clearToast],
  );

  const toastAction = useMemo(
    () => ({
      label: "Close",
      onPress: dismissToast,
    }),
    [dismissToast],
  );

  return (
    <Snackbar
      visible={!!toastNotificationMessage}
      onDismiss={dismissToast}
      duration={1000}
      action={toastAction}
    >
      {toastNotificationMessage}
    </Snackbar>
  );
};
