import React, { ReactNode } from "react";
import { View, ViewStyle, StyleProp } from "react-native";
import { useTheme } from "react-native-paper";
import { AppDarkTheme, AppLightTheme } from "../infrastructure/theme";

interface SpacerProps {
  position?: "top" | "left" | "right" | "bottom";
  size?: "small" | "medium" | "large" | "xl" | "xxl";
  children: ReactNode;
}

const sizeVariant = {
  small: 1,
  medium: 2,
  large: 3,
  xl: 4,
  xxl: 5,
};

const positionVariant = {
  top: "marginTop",
  left: "marginLeft",
  right: "marginRight",
  bottom: "marginBottom",
};

const getVariant = (
  position: keyof typeof positionVariant,
  size: keyof typeof sizeVariant,
  theme: AppLightTheme | AppDarkTheme,
): StyleProp<ViewStyle> => {
  const sizeIndex = sizeVariant[size];
  const property = positionVariant[position];
  const value = theme.space[sizeIndex];

  return { [property]: value };
};

export const Spacer: React.FC<SpacerProps> = ({
  position = "top",
  size = "small",
  children,
}) => {
  const theme = useTheme<AppLightTheme | AppDarkTheme>();
  const variant = getVariant(position, size, theme);
  return <View style={variant}>{children}</View>;
};
