import { registerTranslation } from "react-native-paper-dates";

export const registerDatePickerTranslations = () =>
  registerTranslation("es", {
    save: "Guardar",
    selectSingle: "Seleccionar fecha",
    selectMultiple: "Seleccionar fechas",
    selectRange: "Seleccionar rango",
    notAccordingToDateFormat: (inputFormat) =>
      `El formato debe ser ${inputFormat}`,
    mustBeHigherThan: (date) => `Debe ser más reciente que ${date}`,
    mustBeLowerThan: (date) => `Debe ser más antigua que ${date}`,
    mustBeBetween: (startDate, endDate) =>
      `Debe estar entre ${startDate} - ${endDate}`,
    dateIsDisabled: "La fecha está deshabilitada",
    previous: "Anterior",
    next: "Siguiente",
    typeInDate: "Escriba una fecha",
    pickDateFromCalendar: "Seleccione una fecha del calendario",
    close: "Cerrar",
  });
