import { configureStore } from "@reduxjs/toolkit";
import requestAnalysisSlice from "../features/RequestAnalysis/requestAnalysis.slice";
import requestAnlysisApi from "../features/RequestAnalysis/api/requestAnalysis.api";
import { setupListeners } from "@reduxjs/toolkit/query";
import { appSlice } from "./app.slice";

export const store = configureStore({
  reducer: {
    app: appSlice.reducer,
    requestAnalysis: requestAnalysisSlice.reducer,
    [requestAnlysisApi.reducerPath]: requestAnlysisApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(requestAnlysisApi.middleware),
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
