import { PaperProvider } from "react-native-paper";
import { registerRootComponent } from "expo";
import { Navigation } from "./infrastructure/navigation";
import { AuthenticationContextProvider } from "./infrastructure/authentication/authentication.context";
import {
  AdaptedNavigationDarkTheme,
  AdaptedNavigationLightTheme,
  CustomDarkTheme,
  CustomLightTheme,
  isDarkMode,
} from "./infrastructure/theme";

import "react-native-gesture-handler";

import * as moment from "moment";
import "moment/locale/pt-br";
import { Provider } from "react-redux";
import { store } from "./infrastructure/store";
import { AppToast } from "./infrastructure/components/AppToast.component";
import { registerDatePickerTranslations } from "./infrastructure/i18/datePickerTranslations";
moment.locale("es-SV");

registerDatePickerTranslations();

function App() {
  return (
    <PaperProvider theme={isDarkMode ? CustomDarkTheme : CustomLightTheme}>
      <Provider store={store}>
        <AppToast />
        <AuthenticationContextProvider>
          <Navigation
            theme={
              isDarkMode
                ? AdaptedNavigationDarkTheme
                : AdaptedNavigationLightTheme
            }
          />
        </AuthenticationContextProvider>
      </Provider>
    </PaperProvider>
  );
}

registerRootComponent(App);
