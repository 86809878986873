import { addDoc, collection, getFirestore } from "firebase/firestore";
import { firebaseApp } from "../../../firebaseConfig";
import { getStorage } from "firebase/storage";

export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);

// Raw data
const rawData = [
  {
    analysis: "Ácido Úrico",
    codeCategory: "MEDQU01",
    category: "QUIMICA",
    price: 6.19,
  },
  {
    analysis: "Capacidad de Fijación Hierro Serico",
    codeCategory: "MEDQU02",
    category: "QUIMICA",
    price: 33.63,
  },
  {
    analysis: "Colesterol Total",
    codeCategory: "MEDQU03",
    category: "QUIMICA",
    price: 6.19,
  },
  {
    analysis: "Colesterol-HDL",
    codeCategory: "MEDQU04",
    category: "QUIMICA",
    price: 7.96,
  },
  {
    analysis: "Colsterol-LDL",
    codeCategory: "MEDQU05",
    category: "QUIMICA",
    price: 7.96,
  },
  {
    analysis: "Creatinina",
    codeCategory: "MEDQU06",
    category: "QUIMICA",
    price: 6.19,
  },
  {
    analysis: "Ferritina",
    codeCategory: "MEDQU07",
    category: "QUIMICA",
    price: 35.4,
  },
  {
    analysis: "Fructosamina",
    codeCategory: "MEDQU08",
    category: "QUIMICA",
    price: 43.36,
  },
  {
    analysis: "Glucosa Post/Pandrial",
    codeCategory: "MEDQU09",
    category: "QUIMICA",
    price: 6.19,
  },
  {
    analysis: "Glucosa Tolerancia de 2 horas",
    codeCategory: "MEDQU10",
    category: "QUIMICA",
    price: 24.78,
  },
  {
    analysis: "Glucosa Tolerancia de 3 horas",
    codeCategory: "MEDQU11",
    category: "QUIMICA",
    price: 24.78,
  },
  {
    analysis: "Glucosa Tolerancia de 5 horas",
    codeCategory: "MEDQU12",
    category: "QUIMICA",
    price: 29.2,
  },
  {
    analysis: "Glucosa Verdadera",
    codeCategory: "MEDQU13",
    category: "QUIMICA",
    price: 5.31,
  },
  {
    analysis: "Hemoglobina Glicosilada (Fracción A1C)",
    codeCategory: "MEDQU14",
    category: "QUIMICA",
    price: 24.78,
  },
  {
    analysis: "Hierro Sérico",
    codeCategory: "MEDQU15",
    category: "QUIMICA",
    price: 14.16,
  },
  {
    analysis: "Hierro Sérico mas Capacidad de Fijación",
    codeCategory: "MEDQU16",
    category: "QUIMICA",
    price: 19.47,
  },
  {
    analysis: "Homosisteina",
    codeCategory: "MEDQU17",
    category: "QUIMICA",
    price: 84.07,
  },
  {
    analysis: "Insulina de ayunas",
    codeCategory: "MEDQU18",
    category: "QUIMICA",
    price: 35.4,
  },
  {
    analysis: "Insulina pospandrial",
    codeCategory: "MEDQU19",
    category: "QUIMICA",
    price: 35.4,
  },
  {
    analysis: "Lipidos totales",
    codeCategory: "MEDQU20",
    category: "QUIMICA",
    price: 8.85,
  },
  {
    analysis: "Nitrógeno Ureico",
    codeCategory: "MEDQU21",
    category: "QUIMICA",
    price: 6.19,
  },
  {
    analysis: "Peptido C",
    codeCategory: "MEDQU22",
    category: "QUIMICA",
    price: 78.76,
  },
  {
    analysis: "Test de O’Sullivan",
    codeCategory: "MEDQU23",
    category: "QUIMICA",
    price: 16.55,
  },
  {
    analysis: "Transferina",
    codeCategory: "MEDQU24",
    category: "QUIMICA",
    price: 57.52,
  },
  {
    analysis: "Trigliceridos",
    codeCategory: "MEDQU25",
    category: "QUIMICA",
    price: 7.96,
  },
  {
    analysis: "Albumina",
    codeCategory: "MEDPH01",
    category: "PRUEBAS HEPATICAS",
    price: 6.19,
  },
  {
    analysis: "Bilirrubinas",
    codeCategory: "MEDPH02",
    category: "PRUEBAS HEPATICAS",
    price: 9.51,
  },
  {
    analysis: "Fosfatasa Alcalina",
    codeCategory: "MEDPH03",
    category: "PRUEBAS HEPATICAS",
    price: 7.52,
  },
  {
    analysis: "GGT",
    codeCategory: "MEDPH04",
    category: "PRUEBAS HEPATICAS",
    price: 13.27,
  },
  {
    analysis: "Proteínas Totales con Dif.",
    codeCategory: "MEDPH05",
    category: "PRUEBAS HEPATICAS",
    price: 9.73,
  },
  {
    analysis: "TGO",
    codeCategory: "MEDPH06",
    category: "PRUEBAS HEPATICAS",
    price: 7.52,
  },
  {
    analysis: "TGP",
    codeCategory: "MEDPH07",
    category: "PRUEBAS HEPATICAS",
    price: 7.52,
  },
  {
    analysis: "Ac. Valproico",
    codeCategory: "MEDNM01",
    category: "NIVELES DE MEDICAMENTOS",
    price: 47.79,
  },
  {
    analysis: "Ácido Fólico",
    codeCategory: "MEDNM02",
    category: "NIVELES DE MEDICAMENTOS",
    price: 47.79,
  },
  {
    analysis: "Acido Lactico",
    codeCategory: "MEDNM03",
    category: "NIVELES DE MEDICAMENTOS",
    price: 106.19,
  },
  {
    analysis: "Acido Vanil Mandelico",
    codeCategory: "MEDNM04",
    category: "NIVELES DE MEDICAMENTOS",
    price: 115.04,
  },
  {
    analysis: "Amoniaco",
    codeCategory: "MEDNM05",
    category: "NIVELES DE MEDICAMENTOS",
    price: 42.48,
  },
  {
    analysis: "Carbamazepinas Tegretol",
    codeCategory: "MEDNM06",
    category: "NIVELES DE MEDICAMENTOS",
    price: 47.79,
  },
  {
    analysis: "Cortisol AM (sangre)",
    codeCategory: "MEDNM07",
    category: "NIVELES DE MEDICAMENTOS",
    price: 50.44,
  },
  {
    analysis: "Cortisol PM (sangre)",
    codeCategory: "MEDNM08",
    category: "NIVELES DE MEDICAMENTOS",
    price: 50.44,
  },
  {
    analysis: "Digoxina",
    codeCategory: "MEDNM09",
    category: "NIVELES DE MEDICAMENTOS",
    price: 33.63,
  },
  {
    analysis: "Epamin(Fenitoina, dilantin)",
    codeCategory: "MEDNM10",
    category: "NIVELES DE MEDICAMENTOS",
    price: 33.63,
  },
  {
    analysis: "Estradiol",
    codeCategory: "MEDNM11",
    category: "NIVELES DE MEDICAMENTOS",
    price: 50.44,
  },
  {
    analysis: "Fenobarbital",
    codeCategory: "MEDNM12",
    category: "NIVELES DE MEDICAMENTOS",
    price: 33.63,
  },
  {
    analysis: "Litio",
    codeCategory: "MEDNM13",
    category: "NIVELES DE MEDICAMENTOS",
    price: 25.66,
  },
  {
    analysis: "Vitamina B12",
    codeCategory: "MEDNM14",
    category: "NIVELES DE MEDICAMENTOS",
    price: 42.48,
  },
  {
    analysis: "Vitamina D Total",
    codeCategory: "MEDNM15",
    category: "NIVELES DE MEDICAMENTOS",
    price: 97.35,
  },
  {
    analysis: "ACTH",
    codeCategory: "MEDHO01",
    category: "HORMONAS",
    price: 43.36,
  },
  {
    analysis: "Anti transgutaminasa IgA (Endomiciales IgG)",
    codeCategory: "MEDHO02",
    category: "HORMONAS",
    price: 53.1,
  },
  {
    analysis: "Antigliadina IgA",
    codeCategory: "MEDHO03",
    category: "HORMONAS",
    price: 79.65,
  },
  {
    analysis: "Antigliadina IgG",
    codeCategory: "MEDHO04",
    category: "HORMONAS",
    price: 79.65,
  },
  {
    analysis: "Antigliadina Igg y IgA",
    codeCategory: "MEDHO05",
    category: "HORMONAS",
    price: 115.04,
  },
  {
    analysis: "Anti-microsomales (Peroxidasa)",
    codeCategory: "MEDHO06",
    category: "HORMONAS",
    price: 39.82,
  },
  {
    analysis: "Antimitocondriales",
    codeCategory: "MEDHO07",
    category: "HORMONAS",
    price: 44.25,
  },
  {
    analysis: "Anti-Tiroglobulina",
    codeCategory: "MEDHO08",
    category: "HORMONAS",
    price: 44.25,
  },
  {
    analysis: "Antitiroideoglobulina",
    codeCategory: "MEDHO09",
    category: "HORMONAS",
    price: 38.05,
  },
  {
    analysis: "Beta 2 Microglobulina / B2MG",
    codeCategory: "MEDHO10",
    category: "HORMONAS",
    price: 57.52,
  },
  {
    analysis: "FSH - Hormona Foliculo Estimulante",
    codeCategory: "MEDHO11",
    category: "HORMONAS",
    price: 35.4,
  },
  {
    analysis: "Hormona de Cecimiento",
    codeCategory: "MEDHO12",
    category: "HORMONAS",
    price: 35.4,
  },
  {
    analysis: "Hormona Paratiroidea -PTH",
    codeCategory: "MEDHO13",
    category: "HORMONAS",
    price: 35.4,
  },
  {
    analysis: "LH - Hormona Luteinisante",
    codeCategory: "MEDHO14",
    category: "HORMONAS",
    price: 35.4,
  },
  {
    analysis: "Progesterona",
    codeCategory: "MEDHO15",
    category: "HORMONAS",
    price: 44.25,
  },
  {
    analysis: "Prolactina",
    codeCategory: "MEDHO16",
    category: "HORMONAS",
    price: 35.4,
  },
  {
    analysis: "T3 Libre",
    codeCategory: "MEDHO17",
    category: "HORMONAS",
    price: 15.93,
  },
  {
    analysis: "T3 Total",
    codeCategory: "MEDHO18",
    category: "HORMONAS",
    price: 11.5,
  },
  {
    analysis: "T4 Libre",
    codeCategory: "MEDHO19",
    category: "HORMONAS",
    price: 15.93,
  },
  {
    analysis: "T4 Total",
    codeCategory: "MEDHO20",
    category: "HORMONAS",
    price: 11.5,
  },
  {
    analysis: "Testosterona libre",
    codeCategory: "MEDHO21",
    category: "HORMONAS",
    price: 53.1,
  },
  {
    analysis: "Testosterona total",
    codeCategory: "MEDHO22",
    category: "HORMONAS",
    price: 48.67,
  },
  {
    analysis: "TSH",
    codeCategory: "MEDHO23",
    category: "HORMONAS",
    price: 24.78,
  },
  {
    analysis: "Alfa-fetoproteina",
    codeCategory: "MEDMT01",
    category: "MARCADORES TUMORALES",
    price: 50.44,
  },
  {
    analysis: "Antígeno Carcinoembriogenico ( CEA )",
    codeCategory: "MEDMT02",
    category: "MARCADORES TUMORALES",
    price: 43.36,
  },
  {
    analysis: "Antígeno Prostático Total ( PSA Total )",
    codeCategory: "MEDMT03",
    category: "MARCADORES TUMORALES",
    price: 43.36,
  },
  {
    analysis: "AntígenoProstático Libre ( PSA Libre ) sin %",
    codeCategory: "MEDMT04",
    category: "MARCADORES TUMORALES",
    price: 41.59,
  },
  {
    analysis: "AntígenoProstático Libre / Total y relacion",
    codeCategory: "MEDMT05",
    category: "MARCADORES TUMORALES",
    price: 79.65,
  },
  {
    analysis: "Beta - HCG Cuantitativa",
    codeCategory: "MEDMT06",
    category: "MARCADORES TUMORALES",
    price: 30.97,
  },
  {
    analysis: "CA 125 (ovario)",
    codeCategory: "MEDMT07",
    category: "MARCADORES TUMORALES",
    price: 39.82,
  },
  {
    analysis: "CA 15,3 (mamas)",
    codeCategory: "MEDMT08",
    category: "MARCADORES TUMORALES",
    price: 39.82,
  },
  {
    analysis: "CA, 19,9 (pancrea)",
    codeCategory: "MEDMT09",
    category: "MARCADORES TUMORALES",
    price: 39.82,
  },
  {
    analysis: "Aldolasa",
    codeCategory: "MEDEZ01",
    category: "ENZIMAS",
    price: 48.67,
  },
  {
    analysis: "Amilasa",
    codeCategory: "MEDEZ02",
    category: "ENZIMAS",
    price: 7.08,
  },
  {
    analysis: "CPK MB",
    codeCategory: "MEDEZ03",
    category: "ENZIMAS",
    price: 35.4,
  },
  {
    analysis: "CPK Total",
    codeCategory: "MEDEZ04",
    category: "ENZIMAS",
    price: 23.89,
  },
  {
    analysis: "Fosfatasa Acida Prostática",
    codeCategory: "MEDEZ05",
    category: "ENZIMAS",
    price: 12.39,
  },
  {
    analysis: "Fosfatasa Acida Total",
    codeCategory: "MEDEZ06",
    category: "ENZIMAS",
    price: 8.85,
  },
  {
    analysis: "LDH",
    codeCategory: "MEDEZ07",
    category: "ENZIMAS",
    price: 23.01,
  },
  {
    analysis: "Lipasa",
    codeCategory: "MEDEZ08",
    category: "ENZIMAS",
    price: 16.81,
  },
  {
    analysis: "PCR ULTRA SENSIBLE CARDIACA",
    codeCategory: "MEDEZ09",
    category: "ENZIMAS",
    price: 38.94,
  },
  {
    analysis: "PRO-BNP",
    codeCategory: "MEDEZ10",
    category: "ENZIMAS",
    price: 76.11,
  },
  {
    analysis: "Sodio",
    codeCategory: "MEDEL01",
    category: "ELECTROLITOS",
    price: 7.96,
  },
  {
    analysis: "Potasio",
    codeCategory: "MEDEL02",
    category: "ELECTROLITOS",
    price: 7.96,
  },
  {
    analysis: "Fosforo",
    codeCategory: "MEDEL03",
    category: "ELECTROLITOS",
    price: 7.96,
  },
  {
    analysis: "Magnesio",
    codeCategory: "MEDEL04",
    category: "ELECTROLITOS",
    price: 14.16,
  },
  {
    analysis: "Calcio",
    codeCategory: "MEDEL05",
    category: "ELECTROLITOS",
    price: 7.96,
  },
  {
    analysis: "Cloro",
    codeCategory: "MEDEL06",
    category: "ELECTROLITOS",
    price: 7.96,
  },
  {
    analysis: "Anti MPO ( c-ANCA) Anticitoplasma del neutrofilo Citoplasmatico",
    codeCategory: "MEDIN02",
    category: "INMUNOLOGIA",
    price: 66.37,
  },
  {
    analysis: "Anti MPO ( p-ANCA) Anticitoplasma del neutrofilo Perinuclear",
    codeCategory: "MEDIN03",
    category: "INMUNOLOGIA",
    price: 66.37,
  },
  {
    analysis: "Anticardiolipinas IgM",
    codeCategory: "MEDIN04",
    category: "INMUNOLOGIA",
    price: 38.94,
  },
  {
    analysis: "Anticardiolipinas IgG",
    codeCategory: "MEDIN05",
    category: "INMUNOLOGIA",
    price: 38.94,
  },
  {
    analysis: "Anticoagulante Lupico Cualitativo",
    codeCategory: "MEDIN06",
    category: "INMUNOLOGIA",
    price: 35.4,
  },
  {
    analysis: "Anticoagulante Lupico Cuantificado",
    codeCategory: "MEDIN07",
    category: "INMUNOLOGIA",
    price: 66.37,
  },
  {
    analysis: "Anticuepos de Helicobacter Pylori sangre IgA",
    codeCategory: "MEDIN08",
    category: "INMUNOLOGIA",
    price: 48.67,
  },
  {
    analysis: "Anticuepos de Helicobacter Pylori sangre IgG",
    codeCategory: "MEDIN09",
    category: "INMUNOLOGIA",
    price: 42.48,
  },
  {
    analysis: "Anticuepos de Helicobacter Pylori sangre IgM",
    codeCategory: "MEDIN10",
    category: "INMUNOLOGIA",
    price: 42.48,
  },
  {
    analysis: "Anticuepos de Helicobacter Pylori sangre total",
    codeCategory: "MEDIN11",
    category: "INMUNOLOGIA",
    price: 42.48,
  },
  {
    analysis: "Anticuerpos anticitrulinado (CCP)",
    codeCategory: "MEDIN12",
    category: "INMUNOLOGIA",
    price: 66.37,
  },
  {
    analysis: "Anticuerpos Antinucleares (ANA)",
    codeCategory: "MEDIN13",
    category: "INMUNOLOGIA",
    price: 43.36,
  },
  {
    analysis: "Anticuerpos de Hepatitis A - IgM",
    codeCategory: "MEDIN14",
    category: "INMUNOLOGIA",
    price: 43.36,
  },
  {
    analysis: "Anticuerpos de Hepatitis B HBc-IgM",
    codeCategory: "MEDIN15",
    category: "INMUNOLOGIA",
    price: 43.36,
  },
  {
    analysis: "Anticuerpos de Hepatitis B HBc-Total",
    codeCategory: "MEDIN16",
    category: "INMUNOLOGIA",
    price: 43.36,
  },
  {
    analysis: "Anticuerpos de Hepatitis C",
    codeCategory: "MEDIN17",
    category: "INMUNOLOGIA",
    price: 50.44,
  },
  {
    analysis: "Anticuerpos para Chagas Totales Cualitativos",
    codeCategory: "MEDIN19",
    category: "INMUNOLOGIA",
    price: 50.44,
  },
  {
    analysis: "Antiestreptolisina O",
    codeCategory: "MEDIN20",
    category: "INMUNOLOGIA",
    price: 11.06,
  },
  {
    analysis: "Antigenos Australiano para Hepatitis B (HBSAG)",
    codeCategory: "MEDIN21",
    category: "INMUNOLOGIA",
    price: 43.36,
  },
  {
    analysis: "Antígenos febriles",
    codeCategory: "MEDIN22",
    category: "INMUNOLOGIA",
    price: 17.7,
  },
  {
    analysis: "Antimitocondriales",
    codeCategory: "MEDIN23",
    category: "INMUNOLOGIA",
    price: 44.25,
  },
  {
    analysis: "C3, Complemento",
    codeCategory: "MEDIN24",
    category: "INMUNOLOGIA",
    price: 38.94,
  },
  {
    analysis: "C4,Complemento",
    codeCategory: "MEDIN25",
    category: "INMUNOLOGIA",
    price: 38.94,
  },
  {
    analysis: "Citomegalovirus IgG",
    codeCategory: "MEDIN26",
    category: "INMUNOLOGIA",
    price: 26.55,
  },
  {
    analysis: "Citomegalovirus IgM",
    codeCategory: "MEDIN27",
    category: "INMUNOLOGIA",
    price: 26.55,
  },
  {
    analysis: "Colinesterasa",
    codeCategory: "MEDIN28",
    category: "INMUNOLOGIA",
    price: 37.17,
  },
  {
    analysis: "Dengue IgG (Cualitativo)",
    codeCategory: "MEDIN29",
    category: "INMUNOLOGIA",
    price: 43.36,
  },
  {
    analysis: "Dengue IgG (Cuantitativo)",
    codeCategory: "MEDIN30",
    category: "INMUNOLOGIA",
    price: 43.36,
  },
  {
    analysis: "Dengue IgM (Cualitativo)",
    codeCategory: "MEDIN31",
    category: "INMUNOLOGIA",
    price: 43.36,
  },
  {
    analysis: "Dengue IgM (Cuatitativo)",
    codeCategory: "MEDIN32",
    category: "INMUNOLOGIA",
    price: 43.36,
  },
  {
    analysis: "DNA Nativo",
    codeCategory: "MEDIN33",
    category: "INMUNOLOGIA",
    price: 55.75,
  },
  {
    analysis: "Grupo Sanguíneo Y Factor Rh",
    codeCategory: "MEDIN34",
    category: "INMUNOLOGIA",
    price: 5.31,
  },
  {
    analysis: "HIV",
    codeCategory: "MEDIN35",
    category: "INMUNOLOGIA",
    price: 24.78,
  },
  {
    analysis: "Inmunoglobulina Ig A",
    codeCategory: "MEDIN36",
    category: "INMUNOLOGIA",
    price: 38.94,
  },
  {
    analysis: "Inmunoglobulina Ig E",
    codeCategory: "MEDIN37",
    category: "INMUNOLOGIA",
    price: 38.94,
  },
  {
    analysis: "Inmunoglobulina Ig G",
    codeCategory: "MEDIN38",
    category: "INMUNOLOGIA",
    price: 38.94,
  },
  {
    analysis: "Inmunoglobulina Ig M",
    codeCategory: "MEDIN39",
    category: "INMUNOLOGIA",
    price: 38.94,
  },
  {
    analysis: "Látex RA ( Factor reumatoideo)",
    codeCategory: "MEDIN40",
    category: "INMUNOLOGIA",
    price: 15.93,
  },
  {
    analysis: "Proteina C Reactiva",
    codeCategory: "MEDIN41",
    category: "INMUNOLOGIA",
    price: 17.7,
  },
  {
    analysis: "Prueba de embarazo en sangre",
    codeCategory: "MEDIN42",
    category: "INMUNOLOGIA",
    price: 10.62,
  },
  {
    analysis: "Toxoplasma IgG",
    codeCategory: "MEDIN43",
    category: "INMUNOLOGIA",
    price: 31.86,
  },
  {
    analysis: "Toxoplasma IgM",
    codeCategory: "MEDIN44",
    category: "INMUNOLOGIA",
    price: 31.86,
  },
  {
    analysis: "VDRL( Sífilis)",
    codeCategory: "MEDIN45",
    category: "INMUNOLOGIA",
    price: 5.31,
  },
  {
    analysis: "Rubeola IgG",
    codeCategory: "MEDIN46",
    category: "INMUNOLOGIA",
    price: 39.82,
  },
  {
    analysis: "Rubeola IgM",
    codeCategory: "MEDIN47",
    category: "INMUNOLOGIA",
    price: 39.82,
  },
  {
    analysis: "Ácido Úrico en Orina de 24 Horas / asar",
    codeCategory: "MEDUR01",
    category: "UROANALISIS",
    price: 10.62,
  },
  {
    analysis: "Albumina en Orina",
    codeCategory: "MEDUR02",
    category: "UROANALISIS",
    price: 7.08,
  },
  {
    analysis: "Amilasa en orina de 24 horas / asar",
    codeCategory: "MEDUR03",
    category: "UROANALISIS",
    price: 11.5,
  },
  {
    analysis: "Calcio en Orina de 24 Horas",
    codeCategory: "MEDUR04",
    category: "UROANALISIS",
    price: 11.5,
  },
  {
    analysis: "Catecolamina en orina de 24 horas",
    codeCategory: "MEDUR05",
    category: "UROANALISIS",
    price: 110.62,
  },
  {
    analysis: "Cloro en orina de 24 horas / Asar",
    codeCategory: "MEDUR06",
    category: "UROANALISIS",
    price: 11.5,
  },
  {
    analysis: "Creatinina en orina de 24 horas / asar",
    codeCategory: "MEDUR07",
    category: "UROANALISIS",
    price: 11.5,
  },
  {
    analysis: "Depuración de Creatinina",
    codeCategory: "MEDUR08",
    category: "UROANALISIS",
    price: 16.81,
  },
  {
    analysis: "Espermograma",
    codeCategory: "MEDUR09",
    category: "UROANALISIS",
    price: 22.12,
  },
  {
    analysis: "Fosforo en orina 24 hrs",
    codeCategory: "MEDUR10",
    category: "UROANALISIS",
    price: 11.5,
  },
  {
    analysis: "General de Orina",
    codeCategory: "MEDUR11",
    category: "UROANALISIS",
    price: 4.42,
  },
  {
    analysis: "Magnesio en orina de 24 horas",
    codeCategory: "MEDUR12",
    category: "UROANALISIS",
    price: 11.5,
  },
  {
    analysis: "Nitrógeno Ureico en Orina de 24 Horas",
    codeCategory: "MEDUR13",
    category: "UROANALISIS",
    price: 16.81,
  },
  {
    analysis: "Potasio en Orina de 24 Horas",
    codeCategory: "MEDUR14",
    category: "UROANALISIS",
    price: 11.5,
  },
  {
    analysis: "Proteínas en orina de 24 Horas",
    codeCategory: "MEDUR15",
    category: "UROANALISIS",
    price: 14.16,
  },
  {
    analysis: "Prueba de embarazo en orina",
    codeCategory: "MEDUR16",
    category: "UROANALISIS",
    price: 13.27,
  },
  {
    analysis: "Relacion Albumina Cretinina",
    codeCategory: "MEDUR17",
    category: "UROANALISIS",
    price: 22.12,
  },
  {
    analysis: "Sodio en Orina de 24 Horas",
    codeCategory: "MEDUR18",
    category: "UROANALISIS",
    price: 11.5,
  },
  {
    analysis: "Antígeno de Helicobacter Pylori heces",
    codeCategory: "MEDCO01",
    category: "COPROLOGIA",
    price: 38.94,
  },
  {
    analysis: "Antigeno de salmonella Typhi",
    codeCategory: "MEDCO02",
    category: "COPROLOGIA",
    price: 44.25,
  },
  {
    analysis: "General de heces",
    codeCategory: "MEDCO03",
    category: "COPROLOGIA",
    price: 4.42,
  },
  {
    analysis: "Inmunofluorecencia en heces",
    codeCategory: "MEDCO04",
    category: "COPROLOGIA",
    price: 39.82,
  },
  {
    analysis: "Prueba de Azul de metileno",
    codeCategory: "MEDCO05",
    category: "COPROLOGIA",
    price: 7.08,
  },
  {
    analysis: "Sangre Oculta en heces",
    codeCategory: "MEDCO06",
    category: "COPROLOGIA",
    price: 7.08,
  },
  {
    analysis: "Sustancias Reductoras",
    codeCategory: "MEDCO07",
    category: "COPROLOGIA",
    price: 15.93,
  },
  {
    analysis: "Gota Gruesa",
    codeCategory: "MEDHE01",
    category: "HEMATOLOGIA",
    price: 9.73,
  },
  {
    analysis: "Células L E",
    codeCategory: "MEDHE02",
    category: "HEMATOLOGIA",
    price: 17.7,
  },
  {
    analysis: "Células Latex LE",
    codeCategory: "MEDHE03",
    category: "HEMATOLOGIA",
    price: 17.7,
  },
  {
    analysis: "Coombs Directo",
    codeCategory: "MEDHE04",
    category: "HEMATOLOGIA",
    price: 14.16,
  },
  {
    analysis: "Coombs Indirecto",
    codeCategory: "MEDHE05",
    category: "HEMATOLOGIA",
    price: 14.16,
  },
  {
    analysis: "Eosinofilos en Moco Nasal",
    codeCategory: "MEDHE06",
    category: "HEMATOLOGIA",
    price: 9.73,
  },
  {
    analysis: "Eosinofilos en Sangre",
    codeCategory: "MEDHE07",
    category: "HEMATOLOGIA",
    price: 9.73,
  },
  {
    analysis: "Eritrosedimentacion",
    codeCategory: "MEDHE08",
    category: "HEMATOLOGIA",
    price: 5.31,
  },
  {
    analysis: "Factor de U",
    codeCategory: "MEDHE09",
    category: "HEMATOLOGIA",
    price: 19.47,
  },
  {
    analysis: "Frotis de Sangre Periférica",
    codeCategory: "MEDHE10",
    category: "HEMATOLOGIA",
    price: 17.7,
  },
  {
    analysis: "Hematocrito y Hemoglobina",
    codeCategory: "MEDHE11",
    category: "HEMATOLOGIA",
    price: 4.42,
  },
  {
    analysis: "Hemograma completo",
    codeCategory: "MEDHE12",
    category: "HEMATOLOGIA",
    price: 8.85,
  },
  {
    analysis: "Leucograma",
    codeCategory: "MEDHE13",
    category: "HEMATOLOGIA",
    price: 4.42,
  },
  {
    analysis: "Miologlobina",
    codeCategory: "MEDHE14",
    category: "HEMATOLOGIA",
    price: 66.37,
  },
  {
    analysis: "Plaquetas",
    codeCategory: "MEDHE15",
    category: "HEMATOLOGIA",
    price: 4.42,
  },
  {
    analysis: "Reticulocitos",
    codeCategory: "MEDHE16",
    category: "HEMATOLOGIA",
    price: 10.62,
  },
  {
    analysis: "Dimero D",
    codeCategory: "MEDCO01",
    category: "COAGULACION",
    price: 66.37,
  },
  {
    analysis: "Fibrinógeno",
    codeCategory: "MEDCO02",
    category: "COAGULACION",
    price: 17.7,
  },
  {
    analysis: "Retracción del Coagulo",
    codeCategory: "MEDCO03",
    category: "COAGULACION",
    price: 8.85,
  },
  {
    analysis: "Tiempo de Coagulación",
    codeCategory: "MEDCO04",
    category: "COAGULACION",
    price: 4.42,
  },
  {
    analysis: "Tiempo de Sangramiento",
    codeCategory: "MEDCO05",
    category: "COAGULACION",
    price: 4.42,
  },
  {
    analysis: "Tiempo de Trombina",
    codeCategory: "MEDCO06",
    category: "COAGULACION",
    price: 14.16,
  },
  {
    analysis: "Tiempo Parcial de Tromboplastina",
    codeCategory: "MEDCO07",
    category: "COAGULACION",
    price: 8.85,
  },
  {
    analysis: "Tiempo y Valor de Protrombina",
    codeCategory: "MEDCO08",
    category: "COAGULACION",
    price: 8.85,
  },
  {
    analysis: "Baciloscopia",
    codeCategory: "MEDBA01",
    category: "BACTERIOLOGIA",
    price: 7.08,
  },
  {
    analysis: "Coprocultivo",
    codeCategory: "MEDBA02",
    category: "BACTERIOLOGIA",
    price: 15.93,
  },
  {
    analysis: "Cultivo de esputo",
    codeCategory: "MEDBA03",
    category: "BACTERIOLOGIA",
    price: 17.7,
  },
  {
    analysis: "Cultivo de Hongos mas KOH",
    codeCategory: "MEDBA04",
    category: "BACTERIOLOGIA",
    price: 26.55,
  },
  {
    analysis: "Cultivo de Secreción Otros:",
    codeCategory: "MEDBA05",
    category: "BACTERIOLOGIA",
    price: 17.7,
  },
  {
    analysis: "Cultivo de Secreción vaginal",
    codeCategory: "MEDBA06",
    category: "BACTERIOLOGIA",
    price: 17.7,
  },
  {
    analysis: "Cultivo faríngeo",
    codeCategory: "MEDBA07",
    category: "BACTERIOLOGIA",
    price: 17.7,
  },
  {
    analysis: "Directo de GRAM",
    codeCategory: "MEDBA08",
    category: "BACTERIOLOGIA",
    price: 7.96,
  },
  {
    analysis: "Directo de KOH",
    codeCategory: "MEDBA09",
    category: "BACTERIOLOGIA",
    price: 8.85,
  },
  {
    analysis: "Espermocultivo",
    codeCategory: "MEDBA10",
    category: "BACTERIOLOGIA",
    price: 17.7,
  },
  {
    analysis: "Hemocultivo",
    codeCategory: "MEDBA11",
    category: "BACTERIOLOGIA",
    price: 25.66,
  },
  {
    analysis: "Urocultivo",
    codeCategory: "MEDBA12",
    category: "BACTERIOLOGIA",
    price: 15.93,
  },
  {
    analysis: "Anfetaminas/Metanfetaminas en orina",
    codeCategory: "MEDDR01",
    category: "DROGAS DE ABUSO",
    price: 30.97,
  },
  {
    analysis: "Benzodiacepinas orina",
    codeCategory: "MEDDR02",
    category: "DROGAS DE ABUSO",
    price: 28.32,
  },
  {
    analysis: "Cocaína en orina",
    codeCategory: "MEDDR03",
    category: "DROGAS DE ABUSO",
    price: 28.32,
  },
  {
    analysis: "Drogas de 10 parametros en orina",
    codeCategory: "MEDDR04",
    category: "DROGAS DE ABUSO",
    price: 57.52,
  },
  {
    analysis: "Drogas de 5 parametros en orina",
    codeCategory: "MEDDR05",
    category: "DROGAS DE ABUSO",
    price: 39.82,
  },
  {
    analysis: "Marihuana orina",
    codeCategory: "MEDDR06",
    category: "DROGAS DE ABUSO",
    price: 28.32,
  },
];

export const insertData = async () => {
  console.log("inserting data");
  const categoryCollection = collection(db, "analysesCategories");
  const analysisCollection = collection(db, "analyses");

  // unique categories set
  const uniqueCategories = [...new Set(rawData.map((data) => data.category))];

  // insert categories into firestore
  const categoryDocs = [];
  for (const category of uniqueCategories) {
    const categoryDoc = await addDoc(categoryCollection, { name: category });
    categoryDocs.push(categoryDoc);
  }

  // map of category names to their ids
  const categoryNameToId = new Map<string, string>();
  categoryDocs.forEach((doc, index) => {
    categoryNameToId.set(uniqueCategories[index], doc.id);
  });

  // insert analyses into firestore
  for (const data of rawData) {
    const { analysis, price, category } = data;
    await addDoc(analysisCollection, {
      name: analysis,
      price,
      categoryId: categoryNameToId.get(category),
    });
  }
  console.log("done");
};
