import { useMemo } from "react";
import {
  useGetAnalysisCategoriesQuery,
  useGetAnalysisQuery,
} from "../api/requestAnalysis.api";
import { useAppSelector } from "../../../infrastructure/hooks";
import { selectedAnalysesSet } from "../selectors";

export interface Analysis {
  id: string;
  name: string;
  categoryId: string;
}

export interface AnalysisSection {
  title: string;
  data: Analysis[];
}

const useAnalysisList = () => {
  const selectedAnalysisSet = useAppSelector(selectedAnalysesSet);

  const { data: categories, isFetching: isFetchingCategories } =
    useGetAnalysisCategoriesQuery();

  const { data: analysis, isFetching: isFetchingAnalysis } =
    useGetAnalysisQuery();

  const analysisByCategory = useMemo(() => {
    const analysisSectionsMap = new Map<string, AnalysisSection>();
    if (!analysis || !categories) {
      return analysisSectionsMap;
    }

    categories.forEach((category) => {
      analysisSectionsMap.set(category.id, {
        title: category.name,
        data: [],
      });
    });

    analysis.forEach((analysis) => {
      const section = analysisSectionsMap.get(analysis.categoryId);
      if (section) {
        section.data.push(analysis);
      }
    });

    return analysisSectionsMap;
  }, [analysis, categories]);

  const selectedAnalysesBySection = useMemo(() => {
    if (selectedAnalysisSet.size === 0) {
      return [];
    }

    const filteredAnalyses = new Map<string, AnalysisSection>();
    analysisByCategory.forEach((section, key) => {
      const filteredData = section.data.filter((analysis) =>
        selectedAnalysisSet.has(analysis.id),
      );
      if (filteredData.length > 0) {
        filteredAnalyses.set(key, { title: section.title, data: filteredData });
      }
    });
    return Array.from(filteredAnalyses.values());
  }, [selectedAnalysisSet, analysisByCategory]);

  return {
    analysesByCategory: analysisByCategory,
    selectedAnalysesBySection: selectedAnalysesBySection,
    isFetchingAnalyses: isFetchingCategories || isFetchingAnalysis,
  };
};

export default useAnalysisList;
