import { useWindowDimensions } from "react-native";

export const useScreen = () => {
  const { width, height } = useWindowDimensions();

  const isSmallScreen = width < 600;

  return {
    width,
    height,
    isSmallScreen,
  };
};
