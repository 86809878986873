import { StyleSheet, Dimensions } from "react-native";

interface ResponsiveStylesheet<
  T extends StyleSheet.NamedStyles<T>,
  P extends StyleSheet.NamedStyles<P>,
> {
  combined: boolean;
  default: T;
  smallSreen: P;
}

function createStyle<
  T extends StyleSheet.NamedStyles<T>,
  P extends StyleSheet.NamedStyles<P>,
>(definition: ResponsiveStylesheet<T, P>): T | P | (T & P) {
  const {
    default: defaultStyles,
    smallSreen: smallScreenStyles,
    combined,
  } = definition;
  const isSmallScreen = Dimensions.get("window").width < 600;

  const defaultStylesheet = StyleSheet.create(defaultStyles);

  if (!isSmallScreen) return defaultStylesheet;

  const smallScreenStylesheet = StyleSheet.create(smallScreenStyles);
  if (!combined) return smallScreenStylesheet;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const allStyleKeys: Array<keyof T | keyof P> = [
    ...Object.keys(defaultStyles),
    ...Object.keys(smallScreenStyles),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ] as any;
  return allStyleKeys.reduce(
    (sheet, styleName) => {
      sheet[styleName] = [
        defaultStylesheet[styleName],
        smallScreenStylesheet[styleName],
      ];
      return sheet;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    {} as { [K in keyof T | keyof P]: any },
  );
}

export const ResponsiveStylesheet = {
  createStyle,
};
