import React, { useEffect } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { ActivityIndicator, List, Text, TextInput } from "react-native-paper";
import { DatePickerInput } from "react-native-paper-dates";
import { FlatList } from "react-native-gesture-handler";
import { ResponsiveStylesheet } from "../../infrastructure/theme/responsiveStylesheet";
import moment from "moment";
import { HomeStackParamList } from "../../infrastructure/navigation/Home.navigator";
import { useNavigation } from "@react-navigation/native";
import { StackScreenProps } from "@react-navigation/stack";
import { useAppDispatch, useAppSelector } from "../../infrastructure/hooks";
import {
  patientSelected,
  selectRequestAnalysisSelectedPatient,
} from "../RequestAnalysis/requestAnalysis.slice";
import {
  PatientResponse,
  useLazyGetPatientByBirthdateAndNameQuery,
} from "../RequestAnalysis/api/requestAnalysis.api";

interface PatientSelectorProps {
  style?: StyleProp<ViewStyle>;
}

type PatientSelectorScreenProps = StackScreenProps<
  HomeStackParamList,
  "PatientSelector"
>;

type PatientSelectorScreenNavigationProp =
  PatientSelectorScreenProps["navigation"];

export const PatientSelectorScreen = () => {
  const appDispatch = useAppDispatch();
  const navigation = useNavigation();

  const [searchPatient, { isFetching, data: patients }] =
    useLazyGetPatientByBirthdateAndNameQuery();

  const [birthDate, setBirthDate] = React.useState<Date | undefined>(undefined);
  const [names, setNames] = React.useState<string>("");

  useEffect(() => {
    if (!names || !birthDate) {
      return;
    }

    void searchPatient({ birthdate: birthDate, name: names });
  }, [searchPatient, names, birthDate]);

  const onPatientSelected = (patient: PatientResponse) => {
    appDispatch(
      patientSelected({
        id: patient.objectID,
        firstName: patient.firstName,
        lastName: patient.lastName,
        dateOfBirth: patient.dateOfBirth,
      }),
    );
    navigation.goBack();
  };

  const emptyContent = (
    <Text
      variant="bodyLarge"
      style={{ textAlign: "center", marginVertical: 10 }}
    >
      No se encontraron pacientes
    </Text>
  );

  return (
    <View style={modalResponsiveStyles.container}>
      <View style={modalResponsiveStyles.inputContainer}>
        <DatePickerInput
          style={modalResponsiveStyles.dateInput}
          locale="es"
          mode="outlined"
          label="Fecha de Nacimiento"
          withDateFormatInLabel={true}
          withModal={false}
          value={birthDate}
          onChangeText={(t) => (t ?? "" === "") && setBirthDate(undefined)}
          onChange={(d) => setBirthDate(d)}
          inputMode="start"
          saveLabel="Aceptar"
        />
        <TextInput
          mode="outlined"
          label="Nombres"
          style={modalResponsiveStyles.namesInput}
          value={names}
          onChangeText={(value) => setNames(value)}
        />
      </View>
      <FlatList
        data={patients}
        renderItem={({ item }) => (
          <List.Item
            title={`${item.firstName} ${item.lastName}`}
            onPress={() => onPatientSelected(item)}
          />
        )}
        keyExtractor={(item) => item.objectID}
        ListEmptyComponent={
          isFetching ? <ActivityIndicator animating={true} /> : emptyContent
        }
      />
    </View>
  );
};

export const PatientSelector = ({ style }: PatientSelectorProps) => {
  const selectedPatient = useAppSelector(selectRequestAnalysisSelectedPatient);
  const { firstName = "", lastName = "", dateOfBirth } = selectedPatient ?? {};
  const fullName = `${firstName} ${lastName}`.trim();

  const navigation = useNavigation<PatientSelectorScreenNavigationProp>();

  const onSearchFocus = () => {
    navigation.navigate("PatientSelector");
  };

  return (
    <View style={style}>
      <Text variant="titleMedium" style={selectorResponsiveStyles.label}>
        Datos del Paciente
      </Text>
      <View style={modalResponsiveStyles.inputContainer}>
        <TextInput
          mode="outlined"
          label={fullName ? "Nombres" : "Buscar paciente..."}
          style={modalResponsiveStyles.namesInput}
          value={fullName}
          onFocus={onSearchFocus}
          left={<TextInput.Icon icon="account" />}
        />
        {dateOfBirth && (
          <TextInput
            mode="outlined"
            label="Fecha de nacimiento"
            style={modalResponsiveStyles.dateInput}
            value={moment(dateOfBirth).format("DD/MM/YYYY")}
          />
        )}
      </View>
    </View>
  );
};

const modalResponsiveStyles = ResponsiveStylesheet.createStyle({
  combined: true,
  default: {
    container: {
      flex: 1,
      marginTop: 10,
      marginBottom: 10,
      marginHorizontal: 10,
    },
    inputContainer: {
      justifyContent: "flex-start",
      gap: 10,
      marginBottom: 10,
      flexDirection: "row",
      flexWrap: "wrap",
    },
    dateInput: {
      flex: 1,
    },
    namesInput: {
      flex: 3,
    },
  },
  smallSreen: {
    container: {},
    inputContainer: {
      flexDirection: "column",
      flexWrap: "nowrap",
    },
    dateInput: {},
    namesInput: {},
    label: {},
  },
});

const selectorResponsiveStyles = ResponsiveStylesheet.createStyle({
  combined: true,
  default: {
    label: {
      marginBottom: 5,
    },
  },
  smallSreen: {
    label: {},
  },
});
