import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Checkbox, Searchbar, Text } from "react-native-paper";
import { useAppDispatch, useAppSelector } from "../../infrastructure/hooks";
import { analysisSelected, selectedAnalysis } from "./requestAnalysis.slice";
import { showToast } from "../../infrastructure/app.slice";
import { SectionList, StyleSheet } from "react-native";
import useAnalysisList, { AnalysisSection } from "./hooks/useAnalysisList.hook";
import { selectedAnalysesSet } from "./selectors";
import { HomeStackParamList } from "../../infrastructure/navigation/Home.navigator";
import { StackScreenProps } from "@react-navigation/stack";

const styles = StyleSheet.create({
  analysesContainer: {
    flex: 1,
    marginHorizontal: 10,
  },
  analysisItem: {},
});

type AnalysisSelectorScreenProps = StackScreenProps<
  HomeStackParamList,
  "AnalysisSelector"
>;

export default function AnalysisSelectorScreen({
  navigation,
}: AnalysisSelectorScreenProps) {
  const appDispatch = useAppDispatch();
  const { analysesByCategory } = useAnalysisList();
  const selectedAnalyses = useAppSelector(selectedAnalysis);
  const selectedAnalysisSet = useAppSelector(selectedAnalysesSet);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const searchRef = useRef<any>(null);

  const onAcceptPressed = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => <Button onPress={onAcceptPressed}>Aceptar</Button>,
    });
  }, [navigation]);

  const [searchQuery, setSearchQuery] = useState("");
  const onSearchChanged = useCallback((text: string) => {
    setSearchQuery(text);
  }, []);

  const toggleSelectedAnalysis = (analysisId: string) => {
    appDispatch(analysisSelected(analysisId));

    if (searchQuery.trim() !== "") {
      setSearchQuery("");
      appDispatch(showToast("Examen seleccionado"));
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    searchRef.current?.focus();
  };

  const filteredAnalyses = React.useMemo(() => {
    if (searchQuery.trim() === "") {
      return Array.from(analysesByCategory.values());
    }

    const filteredAnalyses = new Map<string, AnalysisSection>();
    analysesByCategory.forEach((section, key) => {
      const filteredData = section.data.filter((analysis) =>
        analysis.name.toLowerCase().includes(searchQuery.toLowerCase()),
      );
      if (filteredData.length > 0) {
        filteredAnalyses.set(key, { title: section.title, data: filteredData });
      }
    });
    return Array.from(filteredAnalyses.values());
  }, [searchQuery, analysesByCategory]);

  const selectedAmount =
    selectedAnalyses.length != 1
      ? `${selectedAnalyses.length} exámenes seleccionados`
      : "1 examen seleccionado";

  return (
    <>
      <Searchbar
        placeholder="Buscar exámenes"
        ref={searchRef}
        mode="bar"
        icon="magnify"
        onChangeText={onSearchChanged}
        value={searchQuery}
        style={{
          marginBottom: 15,
          backgroundColor: "#F5F5F5",
          borderRadius: 5,
        }}
      />
      <SectionList
        style={styles.analysesContainer}
        sections={filteredAnalyses}
        keyExtractor={(item) => item.id}
        renderItem={({ item: analysis }) => (
          <Checkbox.Item
            key={analysis.id}
            label={analysis.name}
            status={
              selectedAnalysisSet.has(analysis.id) ? "checked" : "unchecked"
            }
            onPress={() => toggleSelectedAnalysis(analysis.id)}
            style={styles.analysisItem}
          />
        )}
        ListHeaderComponent={() => (
          <Text variant="bodyLarge" style={{ marginBottom: 15 }}>
            {selectedAmount}
          </Text>
        )}
        renderSectionHeader={({ section: { title } }) => (
          <Text variant="titleMedium" style={{ fontWeight: "bold" }}>
            {title}
          </Text>
        )}
      />
    </>
  );
}
