import React, { useCallback, useContext, useEffect, useState } from "react";
import { SectionList, StyleSheet, View } from "react-native";
import {
  Text,
  Button,
  IconButton,
  Portal,
  Dialog,
  TextInput,
} from "react-native-paper";
import { Spacer } from "../../components/Spacer.component";
import { SafeArea } from "../../infrastructure/components/SafeArea.component";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AuthenticationContext } from "../../infrastructure/authentication/authentication.context";
import { convertYupToFormikErrors } from "../../infrastructure/validation/formik";
import { PatientSelector } from "../Patients/SearchPatient.component";
import useAnalysisList from "./hooks/useAnalysisList.hook";
import { useRequestAnalysesMutation } from "./api/requestAnalysis.api";
import { useAppDispatch, useAppSelector } from "../../infrastructure/hooks";
import {
  analysisSelected,
  selectRequestAnalysisSelectedPatient,
  selectedAnalysis,
} from "./requestAnalysis.slice";
import { showToast } from "../../infrastructure/app.slice";
import { HomeStackParamList } from "../../infrastructure/navigation/Home.navigator";
import { StackScreenProps } from "@react-navigation/stack";

const validationSchema = Yup.object().shape({
  patientId: Yup.string().required("El paciente es requerido"),
  notes: Yup.string(),
});

type RequestAnalysisScreenProps = StackScreenProps<
  HomeStackParamList,
  "RequestAnalysis"
>;

export default function RequestAnalysisScreen({
  navigation,
}: RequestAnalysisScreenProps) {
  const { user } = useContext(AuthenticationContext);

  const appDispatch = useAppDispatch();

  const selectedAnalyses = useAppSelector(selectedAnalysis);
  const selectedPatient = useAppSelector(selectRequestAnalysisSelectedPatient);
  const { selectedAnalysesBySection } = useAnalysisList();

  const [requestAnalysesMutation, { isLoading: isSubmitting }] =
    useRequestAnalysesMutation();

  const [dialogContent, setDialogContent] = useState<
    "form-feedback" | "patient-form" | "none"
  >("none");

  const hideDialog = () => setDialogContent("none");

  const onSearchFocus = useCallback(() => {
    navigation.navigate("AnalysisSelector");
  }, [navigation]);

  const formik = useFormik({
    initialValues: {
      patientId: null,
      selectedAnalyses: new Array<string>(),
      notes: "",
    },
    onSubmit: (values) => {
      requestAnalysesMutation({
        patient: selectedPatient!,
        analyses: selectedAnalyses,
        userId: user?.uid,
        notes: values.notes,
      })
        .unwrap()
        .then(() => {
          // TODO: toast doesn't work
          appDispatch(showToast("Solicitud registrada satisfactoriamente"));
          formik.resetForm();
        })
        .catch(() => {
          //TODO: use mutation errors
          setDialogContent("form-feedback");
        });
    },
    validate: (values) => {
      try {
        validationSchema.validateSync(values, { abortEarly: false });
      } catch (error) {
        if (!(error instanceof Yup.ValidationError)) {
          throw error;
        }

        const formikErrors = convertYupToFormikErrors(error);

        setDialogContent("form-feedback");
        return formikErrors;
      }
    },
    validateOnChange: false,
  });

  useEffect(() => {
    void formik.setFieldValue("patientId", selectedPatient?.id);
  }, [selectedPatient]);

  const toggleSelectedAnalysis = (analysisId: string) => {
    appDispatch(analysisSelected(analysisId));
    appDispatch(showToast("Examen deseleccionado"));
  };

  return (
    <SafeArea style={style.container} isLoading={isSubmitting}>
      <Portal>
        <Dialog
          visible={dialogContent === "form-feedback"}
          onDismiss={hideDialog}
          style={{}}
        >
          <Dialog.Title>
            {formik.isValid
              ? "Solicitud satisfactoria"
              : "Información incorrecta"}
          </Dialog.Title>
          <Dialog.Content>
            {formik.isValid ? (
              <Text variant="bodyMedium">
                La solicitud de exámenes fue registrada satisfactoriamente
              </Text>
            ) : (
              <View>
                {Object.values(formik.errors).map((error, index) => (
                  <Text key={index} variant="bodyMedium">
                    - {error}
                  </Text>
                ))}
              </View>
            )}
          </Dialog.Content>
          <Dialog.Actions style={{ alignSelf: "center" }}>
            <Button onPress={hideDialog}>Aceptar</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
      <PatientSelector style={style.input} />
      <Text variant="titleMedium" style={style.label}>
        Exámenes seleccionados
      </Text>
      <TextInput
        mode="outlined"
        label="Agregar exámenes..."
        style={style.input}
        onFocus={onSearchFocus}
        left={<TextInput.Icon icon="text-search" />}
      />
      <View style={style.analysesContainer}>
        <SectionList
          style={style.sectionList}
          sections={selectedAnalysesBySection}
          keyExtractor={(item) => item.id}
          renderItem={({ item: analysis }) => (
            <View key={analysis.id} style={style.analysisItem}>
              <Text variant="bodyLarge">{analysis.name}</Text>
              <IconButton
                icon="close"
                size={16}
                onPress={() => toggleSelectedAnalysis(analysis.id)}
              />
            </View>
          )}
          ListEmptyComponent={() => (
            <Text variant="bodyLarge" style={style.emptyListMessage}>
              No hay exámenes seleccionados
            </Text>
          )}
          renderSectionHeader={({ section: { title } }) => (
            <Spacer position="bottom" size="small">
              <Text variant="titleMedium" style={style.sectionHeader}>
                {title}
              </Text>
            </Spacer>
          )}
        />
      </View>
      <TextInput
        mode="outlined"
        label="Notas"
        multiline
        style={style.input}
        value={formik.values.notes}
        onChangeText={(p) => void formik.setFieldValue("notes", p)}
      />
      <Button
        style={style.submitButton}
        mode="contained"
        onPress={() => formik.handleSubmit()}
      >
        Solicitar
      </Button>
    </SafeArea>
  );
}

const style = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 10,
    marginBottom: 10,
    marginHorizontal: 10,
  },
  analysesContainer: {
    flex: 1,
  },
  sectionList: {
    flex: 1,
  },
  sectionHeader: {
    fontWeight: "bold",
  },
  analysisItem: {
    flexDirection: "row",
    alignItems: "center",
  },
  emptyListMessage: {
    textAlign: "center",
    marginVertical: 10,
  },
  label: {
    marginBottom: 5,
  },
  input: {
    marginBottom: 10,
  },
  submitButton: {},
});
