import React, { useContext } from "react";
import { AppNavigator } from "./App.navigator";
import { AuthenticationContext } from "../authentication/authentication.context";
import { NavigationContainer, Theme } from "@react-navigation/native";
import { AccountNavigator } from "./Account.navigator";

interface NavigationProps {
  theme: Theme;
}

const linking = {
  prefixes: [
    /* your linking prefixes */
  ],
  // config: {
  //   /* configuration for matching screens with paths */
  // },
};

export const Navigation = ({ theme }: NavigationProps) => {
  const { isAuthenticated } = useContext(AuthenticationContext);

  return (
    <NavigationContainer theme={theme} linking={linking}>
      {isAuthenticated ? <AppNavigator /> : <AccountNavigator />}
    </NavigationContainer>
  );
};
