import React, { useContext } from "react";
import { Ionicons } from "@expo/vector-icons";
import { Image } from "react-native";

import { HomeNavigator } from "./Home.navigator";
import {
  DrawerContentComponentProps,
  DrawerContentScrollView,
  DrawerItem,
  DrawerItemList,
  createDrawerNavigator,
} from "@react-navigation/drawer";
import { useScreen } from "../useScreen.hook";
import { AuthenticationContext } from "../authentication/authentication.context";

export type DrawerParamList = {
  Home: undefined;
  Analysis: undefined;
  Patients: { userId: string };
  Settings: undefined;
};

const DrawerNavigator = createDrawerNavigator<DrawerParamList>();

function CustomDrawerContent(props: DrawerContentComponentProps) {
  const { onLogout } = useContext(AuthenticationContext);

  return (
    <DrawerContentScrollView {...props}>
      <Image
        style={{
          padding: 8,
          marginHorizontal: 10,
          height: 100,
          resizeMode: "contain",
        }}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        source={require("../../../assets/logo-medilab.png")}
      />
      <DrawerItemList {...props} />
      <DrawerItem
        icon={({ color, size }) => (
          <Ionicons name="exit" size={size} color={color} />
        )}
        label="Cerrar sesión"
        onPress={() => onLogout()}
      />
    </DrawerContentScrollView>
  );
}

export const AppNavigator = () => {
  const { isSmallScreen } = useScreen();
  return (
    <DrawerNavigator.Navigator
      useLegacyImplementation
      initialRouteName="Home"
      screenOptions={{
        drawerType: isSmallScreen ? "slide" : "permanent",
        headerLeft: isSmallScreen ? undefined : () => null,
      }}
      drawerContent={(props) => <CustomDrawerContent {...props} />}
    >
      <DrawerNavigator.Group screenOptions={{ headerShown: false }}>
        <DrawerNavigator.Screen
          name="Home"
          options={{ title: "Solicitar Examen" }}
        >
          {(props) => (
            <HomeNavigator {...props} initialRouteName="RequestAnalysis" />
          )}
        </DrawerNavigator.Screen>
        <DrawerNavigator.Screen
          name="Analysis"
          options={{ title: "Ver solicitudes" }}
        >
          {(props) => (
            <HomeNavigator {...props} initialRouteName="AnalysisList" />
          )}
        </DrawerNavigator.Screen>
      </DrawerNavigator.Group>
      {/* <DrawerNavigator.Screen name="Patients" options={{ title: "Pacientes" }} component={Map} /> */}
      {/* <DrawerNavigator.Screen name="Settings" options={{ title: "Ajustes" }} component={Settings} /> */}
    </DrawerNavigator.Navigator>
  );
};
