import React, { useState, useContext } from "react";
import { AuthenticationContext } from "../authentication.context";
import { StyleSheet } from "react-native";
import { Text, TextInput, Button, ActivityIndicator } from "react-native-paper";
import { SafeArea } from "../../components/SafeArea.component";
import { Spacer } from "../../../components/Spacer.component";

const style = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  input: {
    width: 300,
  },
});

export const LoginScreen = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { onLogin, error, isLoading } = useContext(AuthenticationContext);

  if (isLoading) {
    return (
      <SafeArea style={style.container}>
        <ActivityIndicator animating={true} />
      </SafeArea>
    );
  }

  return (
    <SafeArea style={style.container}>
      <Spacer position="bottom" size="small">
        <TextInput
          mode="outlined"
          label="Email"
          style={style.input}
          value={email}
          onChangeText={(u) => setEmail(u)}
        />
      </Spacer>
      <Spacer position="bottom" size="medium">
        <TextInput
          mode="outlined"
          label="Password"
          style={style.input}
          value={password}
          onChangeText={(p) => setPassword(p)}
          onKeyPress={(e) =>
            e.nativeEvent.key === "Enter" && onLogin(email, password)
          }
          secureTextEntry
        />
      </Spacer>
      <Spacer position="bottom" size="medium">
        {error && <Text>Credenciales inválidas</Text>}
      </Spacer>
      <Button mode="contained" onPress={() => onLogin(email, password)}>
        Iniciar Sesión
      </Button>
    </SafeArea>
  );
};
