import React from "react";
import {
  // StackScreenProps,
  createStackNavigator,
} from "@react-navigation/stack";
import RequestAnalysisScreen from "../../features/RequestAnalysis/RequestAnalysis.screen";
import { AnalysisListScreen } from "../../features/ViewAnalysis/AnalysisList.screen";
import { PatientSelectorScreen } from "../../features/Patients/SearchPatient.component";
import AnalysisSelectorScreen from "../../features/RequestAnalysis/AnalysisSelector.screen";
import { DrawerParamList } from "./App.navigator";
import { DrawerScreenProps } from "@react-navigation/drawer";

export type HomeStackParamList = {
  RequestAnalysis: undefined;
  AnalysisList: undefined;
  PatientForm: undefined;
  PatientSelector: undefined;
  AnalysisSelector: undefined;
};

type DrawerHomeProps = DrawerScreenProps<DrawerParamList, "Home">;
type DrawerAnalysesListProps = DrawerScreenProps<DrawerParamList, "Analysis">;

export type HomeNavigatorProps = (DrawerHomeProps | DrawerAnalysesListProps) & {
  initialRouteName: keyof HomeStackParamList;
};

const HomeStack = createStackNavigator<HomeStackParamList>();

export const HomeNavigator = ({ initialRouteName }: HomeNavigatorProps) => {
  return (
    <HomeStack.Navigator initialRouteName={initialRouteName}>
      <HomeStack.Group screenOptions={{ headerShown: false }}>
        <HomeStack.Screen
          name="RequestAnalysis"
          component={RequestAnalysisScreen}
        />
        <HomeStack.Screen name="AnalysisList" component={AnalysisListScreen} />
      </HomeStack.Group>
      <HomeStack.Group screenOptions={{ presentation: "modal" }}>
        <HomeStack.Screen
          name="AnalysisSelector"
          options={{ title: "Selección de exámenes" }}
          component={AnalysisSelectorScreen}
        />
        <HomeStack.Screen
          name="PatientSelector"
          options={{ title: "Selección de paciente" }}
          component={PatientSelectorScreen}
        />
      </HomeStack.Group>
    </HomeStack.Navigator>
  );
};

export const HomeNavigatorWithInitialRoute = (
  routeName: keyof HomeStackParamList,
) => {
  return (props: HomeNavigatorProps) => {
    return <HomeNavigator {...props} initialRouteName={routeName} />;
  };
};
