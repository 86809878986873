import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import { getDocs, collection, addDoc } from "firebase/firestore";
import { db } from "../../../infrastructure/storage/config";
import { documentToType } from "../../../infrastructure/storage/extensions";
import { patientsIndex } from "../../../infrastructure/search";
import moment from "moment";

interface AnalysisCategoryResponse {
  id: string;
  name: string;
}

export interface AnalysisResponse {
  id: string;
  name: string;
  categoryId: string;
}

interface AnalysisRequestPatient {
  id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
}

interface AnalysisRequest {
  patient: AnalysisRequestPatient;
  analyses: string[];
  userId: string | undefined;
  notes: string;
}

export interface PatientResponse {
  objectID: string;
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  phone: string;
}

interface GetPatientsByBirthdateAndNameParams {
  birthdate?: Date;
  name: string;
}

type CustomErrorType = { apiError: string };

export const api = createApi({
  baseQuery: fakeBaseQuery<CustomErrorType>(),
  reducerPath: "analysisApi",
  tagTypes: ["AnalysisCategory", "Analysis", "Patients"],
  endpoints: (build) => ({
    getAnalysisCategories: build.query<AnalysisCategoryResponse[], void>({
      async queryFn() {
        try {
          const categorySnapshot = await getDocs(
            collection(db, "analysesCategories"),
          );

          return {
            data: categorySnapshot.docs.map((doc) =>
              documentToType<AnalysisCategoryResponse>(doc),
            ),
          };
        } catch (error) {
          return {
            error: {
              apiError: "Error getting analysis categories",
            },
          };
        }
      },
      providesTags: [{ type: "AnalysisCategory", id: "LIST" }],
    }),
    getAnalysis: build.query<AnalysisResponse[], void>({
      async queryFn() {
        try {
          const analysisSnapshot = await getDocs(collection(db, "analyses"));

          return {
            data: analysisSnapshot.docs.map((doc) =>
              documentToType<AnalysisResponse>(doc),
            ),
          };
        } catch (error) {
          return {
            error: {
              apiError: "Error getting analysis list",
            },
          };
        }
      },
      providesTags: [{ type: "Analysis", id: "LIST" }],
    }),
    requestAnalyses: build.mutation<void, AnalysisRequest>({
      async queryFn(requestData) {
        try {
          await addDoc(collection(db, "analysisRequests"), {
            patient: requestData.patient,
            analyses: requestData.analyses,
            userId: requestData.userId,
            createdAt: new Date(),
            notes: requestData.notes,
            hasResults: false,
          });

          return { data: undefined };
        } catch (error) {
          return {
            error: {
              apiError: "Error requesting analyses",
            },
          };
        }
      },
    }),
    getPatientByBirthdateAndName: build.query<
      PatientResponse[],
      GetPatientsByBirthdateAndNameParams
    >({
      async queryFn(args) {
        try {
          if (!args.name) {
            return {
              data: [],
            };
          }

          const birthdate = moment(args.birthdate);
          const start = birthdate.clone().subtract(1, "second").unix();
          const end = birthdate.clone().add(1, "second").unix();

          const results = await patientsIndex.search<PatientResponse>(
            args.name,
            {
              filters: args.birthdate
                ? `dateOfBirth:${start} TO ${end} OR dateOfBirth:${
                    start * 1000
                  } TO ${end * 1000}`
                : "",
            },
          );

          return {
            data: results.hits,
          };
        } catch (error) {
          return {
            error: {
              apiError: "Error getting analysis list",
            },
          };
        }
      },
      providesTags: [{ type: "Patients", id: "LIST" }],
    }),
  }),
});

export const {
  useGetAnalysisCategoriesQuery,
  useGetAnalysisQuery,
  useRequestAnalysesMutation,
  useLazyGetPatientByBirthdateAndNameQuery,
} = api;
export default api;
