import {
  MD3DarkTheme as PaperDefaultDarkTheme,
  MD3LightTheme as PaperDefaultLightTheme,
  adaptNavigationTheme,
} from "react-native-paper";
import {
  DefaultTheme as NavigationDefaultLightTheme,
  DarkTheme as NavigationDefaultDarkTheme,
} from "@react-navigation/native";

import { sizes } from "./sizes";
import { lineHeights, space } from "./spacing";

const extraThemeProps = {
  space,
  sizes,
  lineHeights,
};

export const CustomLightTheme = {
  ...PaperDefaultLightTheme,
  ...extraThemeProps,
};

export const CustomDarkTheme = {
  ...PaperDefaultDarkTheme,
  ...extraThemeProps,
};

export type AppLightTheme = typeof CustomLightTheme;
export type AppDarkTheme = typeof CustomDarkTheme;

const CustomNavigationLightTheme = {
  ...NavigationDefaultLightTheme,
  ...extraThemeProps,
};

const CustomNavigationDarkTheme = {
  ...NavigationDefaultDarkTheme,
  ...extraThemeProps,
};

export const {
  LightTheme: AdaptedNavigationLightTheme,
  DarkTheme: AdaptedNavigationDarkTheme,
} = adaptNavigationTheme({
  reactNavigationLight: CustomNavigationLightTheme,
  reactNavigationDark: CustomNavigationDarkTheme,
});

export const isDarkMode = false; //Appearance.getColorScheme() === 'dark';
