import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { LoginScreen } from "../authentication/screens/Login.screen";

// import { RegisterScreen } from "../../features/account/screens/register.screen";

const Stack = createStackNavigator();

export const AccountNavigator = () => (
  <Stack.Navigator screenOptions={{ headerShown: false }}>
    {/* <Stack.Screen name="Main" component={AccountScreen} /> */}
    <Stack.Screen name="Login" component={LoginScreen} />
    {/* <Stack.Screen name="Register" component={RegisterScreen} /> */}
  </Stack.Navigator>
);
