import React, { useContext, useEffect, useMemo, useState } from "react";
import { Linking, Platform, StyleSheet } from "react-native";
import {
  ActivityIndicator,
  Button,
  DataTable,
  Modal,
  Portal,
  Searchbar,
  SegmentedButtons,
  Snackbar,
  Text,
} from "react-native-paper";
import {
  collection,
  getDocs,
  Timestamp,
  query,
  orderBy,
  limit,
  startAfter,
  QueryDocumentSnapshot,
  where,
  and,
  doc,
  arrayUnion,
  updateDoc,
  QueryCompositeFilterConstraint,
  QueryConstraint,
} from "firebase/firestore";
import { db, storage } from "../../infrastructure/storage/config";
import { documentToType } from "../../infrastructure/storage/extensions";
import { SafeArea } from "../../infrastructure/components/SafeArea.component";
import moment from "moment";
import { AuthenticationContext } from "../../infrastructure/authentication/authentication.context";
import * as DocumentPicker from "expo-document-picker";
import {
  UploadMetadata,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import { FlatList } from "react-native-gesture-handler";

interface Patient {
  firstName: string;
  lastName: string;
  birthDate: Timestamp;
  email: string;
  phone: string;
}

interface AnalysisRequest {
  id: string;
  patient: Patient;
  createdAt: Timestamp;
  analyses: string[];
  results?: string[];
  notes: string | null;
}

interface Analysis {
  id: string;
  name: string;
}

export const AnalysisListScreen = () => {
  const [analysisRequests, setAnalysisRequests] = useState<AnalysisRequest[]>(
    [],
  );
  const [page, setPage] = useState<number>(0);
  const itemsPerPage = 10;
  const [isLoading, setIsLoading] = useState(false);
  const [isNextPageAvailable, setIsNextPageAvailable] = useState(true);
  const [pageCursors, setPageCursors] = useState<
    (QueryDocumentSnapshot | null)[]
  >([null, null]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { user, role: userRole } = useContext(AuthenticationContext);
  const [currentAnalysisRequest, setCurrentAnalysisRequest] =
    useState<AnalysisRequest | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedSnackbarVisible, setUploadedSnackbarVisible] = useState(false);

  const isAdmin = userRole === "admin";
  const [analyses, setAnalyses] = useState<Map<string, Analysis>>(new Map());
  const [resultsFilter, setResultsFilter] = useState<"pending" | "completed">(
    "pending",
  );

  const pageTo = (page + 1) * itemsPerPage;

  const selectedAnalyses = useMemo(() => {
    if (!currentAnalysisRequest) {
      return [];
    }

    return currentAnalysisRequest.analyses.map(
      (analysisId) => analyses.get(analysisId)!,
    );
  }, [currentAnalysisRequest]);

  const birthDate = useMemo(() => {
    if (!currentAnalysisRequest) {
      return "";
    }

    return moment(currentAnalysisRequest.patient.birthDate.toDate()).format(
      "L",
    );
  }, [currentAnalysisRequest]);

  const fetchAnalyses = async () => {
    const analysesCollection = collection(db, "analyses");
    const analysesSnapshot = await getDocs(analysesCollection);
    const analyses = analysesSnapshot.docs.map(documentToType<Analysis>);

    const analysesMap = new Map<string, Analysis>();
    analyses.forEach((analysis) => analysesMap.set(analysis.id, analysis));
    setAnalyses(analysesMap);
  };

  const fetchAnalysisRequests = async () => {
    setIsLoading(true);
    const analysisRequestsCollection = collection(db, "analysisRequests");

    // todo: handle null vs empty array
    const userFiltering = where("userId", "==", user?.uid);
    const resultsFiltering = where(
      "hasResults",
      "==",
      resultsFilter === "completed",
    );

    // const searchQueryDate = moment(searchQuery, ['DD/MM/YYYY', 'DD-MM-YYYY'], true);

    let filtering;
    const sortByFields = [];
    const lowerCaseSearchQuery = searchQuery.toLowerCase().trim();
    if (lowerCaseSearchQuery !== "") {
      const searchFiltering = and(
        where("patient.names", ">=", lowerCaseSearchQuery),
        where("patient.names", "<=", lowerCaseSearchQuery + "\uf8ff"),
      );
      sortByFields.push(
        orderBy("patient.names", "asc"),
        orderBy("createdAt", "desc"),
      );

      filtering = isAdmin
        ? and(resultsFiltering, searchFiltering)
        : and(userFiltering, resultsFiltering, searchFiltering);
    } else {
      filtering = isAdmin
        ? resultsFiltering
        : and(userFiltering, resultsFiltering);
      sortByFields.push(orderBy("createdAt", "desc"));
    }

    const nonFilterContraints = pageCursors[page]
      ? [startAfter(pageCursors[page]), ...sortByFields, limit(pageTo)]
      : [...sortByFields, limit(pageTo)];

    const dataQuery =
      filtering instanceof QueryCompositeFilterConstraint
        ? query(analysisRequestsCollection, filtering, ...nonFilterContraints)
        : query(
            analysisRequestsCollection,
            ...[filtering as QueryConstraint, ...nonFilterContraints],
          );

    const analysisRequestsSnapshot = await getDocs(dataQuery);
    const analysisRequests = analysisRequestsSnapshot.docs.map(
      documentToType<AnalysisRequest>,
    );

    if (analysisRequestsSnapshot.docs.length > 0) {
      setPageCursors((cursors) => {
        const newCursors = [...cursors];
        newCursors[page + 1] =
          analysisRequestsSnapshot.docs[
            analysisRequestsSnapshot.docs.length - 1
          ];
        return newCursors;
      });
    }
    setIsNextPageAvailable(
      analysisRequestsSnapshot.docs.length === itemsPerPage,
    );
    setAnalysisRequests(analysisRequests);
    setIsLoading(false);
  };

  useEffect(() => {
    void fetchAnalyses();
  }, []);

  useEffect(() => {
    void fetchAnalysisRequests();
  }, [page, searchQuery, userRole, resultsFilter]);

  const onChangeSearch = (query: string) => {
    setPageCursors([null, null]);
    setPage(0);
    setSearchQuery(query);
  };

  const [adminResultsVisible, setAdminResultsVisible] = React.useState(false);

  const showModal = () => setAdminResultsVisible(true);
  const hideModal = () => {
    setCurrentAnalysisRequest(null);
    setAdminResultsVisible(false);
  };

  const handleManageResults = (item: AnalysisRequest) => {
    setCurrentAnalysisRequest(item);
    showModal();
  };

  const uploadToFirebase = async (uri: string) => {
    setIsUploading(true);
    try {
      const response = await fetch(uri);
      const blob = await response.blob();

      const fileName = `${Date.now()}.pdf`;
      const storageRef = ref(
        storage,
        `analysis-results/${currentAnalysisRequest!.id}/${fileName}`,
      );

      const metadata: UploadMetadata = {
        customMetadata: {
          analysisRequestId: currentAnalysisRequest!.id,
        },
      };

      await uploadBytes(storageRef, blob, metadata);
      await updateDoc(doc(db, "analysisRequests", currentAnalysisRequest!.id), {
        results: arrayUnion(fileName),
        hasResults: true,
      });
      setUploadedSnackbarVisible(true);

      // append to results list
      const currentResults = currentAnalysisRequest!.results || [];
      const updatedAnalysisRequest: AnalysisRequest = {
        ...currentAnalysisRequest!,
        results: [...currentResults, fileName],
      };
      setCurrentAnalysisRequest(updatedAnalysisRequest);
    } catch (error) {
      /* empty */
    }
    setIsUploading(false);
  };

  const pickDocument = () => {
    const performPick = async () => {
      if (!currentAnalysisRequest || isUploading) {
        return;
      }

      try {
        const result = await DocumentPicker.getDocumentAsync({
          type: "application/pdf",
          copyToCacheDirectory: true, // Set to true if you want to copy the file to the app's cache directory
        });

        if (result.type === "success") {
          void uploadToFirebase(result.uri);
        } else if (result.type === "cancel") {
          // User cancelled the picker, exit any dialogs or menus and move on
        }
      } catch (error) {
        // Handle any error that occurred during document picking
      }
    };

    void performPick();
  };

  const downloadResult = (fileName: string) => {
    const performDownload = async () => {
      const url = await getDownloadURL(
        ref(
          storage,
          `analysis-results/${currentAnalysisRequest!.id}/${fileName}`,
        ),
      );
      if (Platform.OS === "web") {
        window.open(url, "_blank");
        return;
      } else {
        void Linking.openURL(url);
      }
    };

    void performDownload();
  };

  const userAction = (item: AnalysisRequest) =>
    item.results?.length ? "Completado" : "Pendiente";

  return (
    <>
      <Portal>
        <Modal
          visible={adminResultsVisible}
          onDismiss={hideModal}
          contentContainerStyle={{ backgroundColor: "white", padding: 20 }}
        >
          <Text
            variant="titleMedium"
            style={{ fontWeight: "bold", marginBottom: 20 }}
          >
            Solicitud de exámenes
          </Text>
          <Text>
            <b>Paciente: </b>
            {currentAnalysisRequest?.patient.firstName}{" "}
            {currentAnalysisRequest?.patient.lastName}
          </Text>
          <Text>
            <b>Fecha de nacimiento: </b>
            {birthDate}
          </Text>
          {currentAnalysisRequest?.patient.email && (
            <Text>
              <b>Email: </b>
              {currentAnalysisRequest?.patient.email}
            </Text>
          )}
          {currentAnalysisRequest?.patient.phone && (
            <Text>
              <b>Teléfono: </b>
              {currentAnalysisRequest?.patient.phone}
            </Text>
          )}
          <Text>
            <b>Notas: </b>
            {currentAnalysisRequest?.notes || "Ninguna"}
          </Text>
          <Text
            variant="titleSmall"
            style={{ fontWeight: "bold", marginTop: 20 }}
          >
            Exámenes
          </Text>
          <FlatList
            data={selectedAnalyses}
            renderItem={({ item: analysis }) => <Text>{analysis.name}</Text>}
          />
          <Text
            variant="titleSmall"
            style={{ fontWeight: "bold", marginTop: 20 }}
          >
            Resultados
          </Text>
          <FlatList
            data={currentAnalysisRequest?.results}
            renderItem={({ item: fileName }) => (
              <Button
                style={{ alignSelf: "flex-start" }}
                onPress={() => downloadResult(fileName)}
              >
                {fileName}
              </Button>
            )}
            keyExtractor={(fileName) => fileName}
            ListEmptyComponent={
              <Text style={{ marginVertical: 10 }}>Aún no hay resultados</Text>
            }
          />
          {isAdmin && (
            <Button
              mode="contained-tonal"
              loading={isUploading}
              onPress={pickDocument}
            >
              Subir resultados
            </Button>
          )}
        </Modal>
        <Snackbar
          visible={uploadedSnackbarVisible}
          onDismiss={() => setUploadedSnackbarVisible(false)}
          duration={2000}
          action={{
            label: "Close",
            onPress: () => {
              setUploadedSnackbarVisible(false);
            },
          }}
        >
          Resultado subido correctamente
        </Snackbar>
      </Portal>
      <SafeArea style={styles.container}>
        <SegmentedButtons
          style={{ marginVertical: 15, marginHorizontal: 10 }}
          value={resultsFilter}
          onValueChange={(value) =>
            setResultsFilter(value as "pending" | "completed")
          }
          buttons={[
            {
              value: "pending",
              label: "Pendiente",
            },
            {
              value: "completed",
              label: "Completados",
            },
          ]}
        />
        <Searchbar
          placeholder="Buscar exámenes por paciente"
          mode="bar"
          icon={"magnify"}
          onChangeText={onChangeSearch}
          value={searchQuery}
          style={{
            marginBottom: 15,
            backgroundColor: "#F5F5F5",
            borderRadius: 5,
          }}
        />
        <DataTable>
          <DataTable.Header>
            <DataTable.Title>Fecha</DataTable.Title>
            <DataTable.Title>Nombres</DataTable.Title>
            <DataTable.Title>Apellidos</DataTable.Title>
            <DataTable.Title>Resultados</DataTable.Title>
          </DataTable.Header>
          {isLoading ? (
            <ActivityIndicator
              animating={true}
              style={{ marginVertical: 10 }}
            />
          ) : (
            analysisRequests.map((item) => (
              <DataTable.Row
                key={item.id}
                onPress={() => handleManageResults(item)}
              >
                <DataTable.Cell>
                  {moment(item.createdAt.toDate()).format("ll")}
                </DataTable.Cell>
                <DataTable.Cell>{item.patient.firstName}</DataTable.Cell>
                <DataTable.Cell>{item.patient.lastName}</DataTable.Cell>
                <DataTable.Cell>{userAction(item)}</DataTable.Cell>
              </DataTable.Row>
            ))
          )}
          <DataTable.Pagination
            page={page}
            numberOfPages={isNextPageAvailable ? page + 2 : page + 1}
            onPageChange={(page) => setPage(page)}
          />
        </DataTable>
      </SafeArea>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
