import React, { FC, ReactNode } from "react";
import {
  StatusBar,
  SafeAreaView,
  StyleSheet,
  ViewStyle,
  ActivityIndicator,
} from "react-native";

interface SafeAreaProps {
  style?: ViewStyle;
  children?: ReactNode;
  isLoading?: boolean;
}

export const SafeArea: FC<SafeAreaProps> = ({ children, style, isLoading }) => {
  if (isLoading) {
    return (
      <SafeAreaView
        style={[styles.safeArea, style, { justifyContent: "center" }]}
      >
        <ActivityIndicator size="large" />
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView style={[styles.safeArea, style]}>{children}</SafeAreaView>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    marginTop: StatusBar.currentHeight || 0,
  },
});
