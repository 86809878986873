import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface AppState {
  toastNotificationMessage?: string;
}

const initialState: AppState = {
  toastNotificationMessage: undefined,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    showToast: (state, action: PayloadAction<string>) => {
      state.toastNotificationMessage = action.payload;
    },
    clearToast: (state) => (state.toastNotificationMessage = undefined),
  },
});

export const { showToast, clearToast } = appSlice.actions;
